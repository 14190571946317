export default {
  alpha: {
    upTak:  '688187', //"692909", //"690130" ,//alpha
    mpTak: "688189",
    rjTak: "688188",
    hrTak: "688191",
    biharTak: "688190",
    delhiTak: "692908",
    cgtak: "690146" ||"693505",    //prod "693505"
  },
};
